.marker-pin {
    width: 36px;
    height: 36px;
    border-radius: 50% 50% 50% 0;
    background: #c30b82;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
  }

.marker-pin::after {
    content: '';
    width: 30px;
    height: 30px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
}

.custom-div-icon i {
    position: absolute;
    width: 22px;
    font-size: 16px;
    font-style: normal;
    left: 0;
    right: 0;
    margin: 14px 10px;
    text-align: center;
}

.cluster-pin {
    width: 36px;
    height: 36px;
    border-radius: 50% 50% 50% 0;
    background: #26C6DA;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
  }

.cluster-pin::after {
    content: '';
    width: 30px;
    height: 30px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
}

.custom-cluster-icon i {
    position: absolute;
    width: 22px;
    font-size: 14px;
    font-style: normal;
    left: 0;
    right: 0;
    margin: 14px 10px;
    text-align: center;
}

.marker-cluster-small {
    /* background-color: rgba(247, 169, 169, 0.6); */
    /* background-color: #ebbcda; */
    background-color: rgba(255, 193, 193, 0.6);
    /* background-color: #fff; */
    border: 1px;
    border-style: solid;
    border-color: #c30b82;
    /* border-color: rgba(255, 223, 223, 0.6); */
}

.marker-cluster-small div {
    /* background-color: rgba(235, 127, 127, 0.6); */
    /* background-color: #ee93ce; */
    /* background-color: rgba(233, 144, 144, 0.6); */
    font-size: medium;
    background-color: #fff;
    position: absolute;
    left: -1px;
    top: -1px;
}